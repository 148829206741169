<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-16">
          <v-row>
            <v-col
              lg="7"
              class="primary d-none d-md-flex align-center justify-center"
            >
              <div class="d-none d-sm-block">
                <div class="d-flex flex-column align-center pa-10">
                  <!-- <div> -->
                  <v-img
                    src="@/assets/images/logo-branca-main.png"
                    max-height="150px"
                    contain
                  ></v-img>
                  <h6
                    class="subtitle-1 mt-4 white--text op-5 font-weight-regular text-justify px-6"
                  >
                    Um software que oferece interatividade com
                    motoristas e parceiros, check-in e out automáticos, dados
                    organizados e muito mais.
                  </h6>
                  <v-btn
                    class="mt-4 text-capitalize"
                    x-large
                    outlined
                    color="white"
                    ><a
                      href="https://www.rotacertasistemas.com.br/"
                      class="white--text"
                      target="_blank"
                    >
                      Mais informações</a
                    ></v-btn
                  >
                  <!-- </div> -->
                </div>
              </div>
            </v-col>
            <v-col lg="5">
              <div class="pa-7 pa-sm-12">
                <v-img
                  src="@/assets/images/logo-main-responsiva.png"
                  max-height="150px"
                  contain
                ></v-img>
                <!-- <img src="@/assets/images/logo-main-responsiva.png" /> -->
                <h2
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Acesso
                </h2>
                <!-- <h6 class="subtitle-1">
                  Não tem uma conta?
                  <a href="#/pages/boxedregister" class>Registro</a>
                </h6> -->

                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  action="/relatorios/dashboard"
                >
                  <v-text-field
                    v-model="user.username"
                    label="Usuário, E-mail, CPF ou CNPJ"
                    class="mt-4 login-input-reset-text-transform"
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    :rules="passwordRules"
                    class="login-input-reset-text-transform"
                    label="Senha"
                    required
                    outlined
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="submit"
                    :type="showPassword ? 'text' : 'password'"
                  ></v-text-field>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    @click="submit"
                    :loading="loading"
                    >Acessar</v-btn
                  >
                  <a class="login-reset-pass" 
                     href="/auth/reset" 
                     role="link" 
                     tabindex="0">Esqueceu a senha?</a>
                </v-form>
              </div>

              <v-snackbar
                v-model="snackbar"
                centered
                color="error"
                elevation="12"
              >
                {{ errorMsg }}

                <template v-slot:action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import User from '~/models/user'
import { mapActions } from 'vuex'
export default {
  name: 'BoxedLogin',

  data: () => ({
    loading: false,
    snackbar: false,
    errorMsg: '',
    valid: true,
    user: {},
    password: '',
    showPassword: false,
    passwordRules: [
      (v) => !!v || 'Senha obrigatória',
      (v) => (v && v.length >= 4) || 'Password must be less than 10 characters',
    ],
    username: '',
    usernameRules: [(v) => !!v || 'Usuário obrigatório'],
    checkbox: false,
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      this.loading = true

      this.$refs.form.validate()
      if (this.$refs.form.validate(true)) {
        if (this.user.username && this.user.password) {
          try {
            var user_data = await this.login(this.user)

            if (user_data.is_reset_senha == true) {
              //var user = encodeURIComponent(user_data.public_id);
              //this.$router.push({ path: '/auth/firstAccess', query: { user }})
              this.$router.push({ path: '/auth/firstAccess' })
            } else {
              this.$router.push({ path: '/' })
            }
          } catch (error) {
            console.error(error)
            if (error.response && error.response.status == 401) {
              this.errorMsg = 'Usuário ou senha inválidos.'
            } else {
              this.errorMsg = 'Ocorreu algum erro inesperado.'
            }
            this.snackbar = true
          }
        }
      }
      this.loading = false
    },
  },
}
</script>

<style>
.login-input-reset-text-transform input {
  text-transform: none;
}
.login-reset-pass {
  margin-top: 12px;
  font-size: 12px;
  line-height: 16px;
  text-align: center!important;
  display: block;
}
</style>
